





































import { RecepcionKtb,ConfirmarTrozas, ConfirmarTrozasModal } from '@/entidades/KTB/RegistroInformacion/RecepcionListadoQuery'
import Alerta from '@/entidades/Sistema/Alerta';
import Loading from '@/entidades/Sistema/Loading';
import {Vue,Component, Prop} from 'vue-property-decorator'
import {State,Action} from 'vuex-class';
@Component
export default class ConfirmarVolumenTrozas extends Vue
{
    @Prop({type:Object,required:false}) item!:RecepcionKtb
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
    @State('usuarioId',{namespace:'authModule'}) usuarioId!:string;
    porcentaje:number =0;
    volumenRecibido:number=0;


    get calcularPorcentaje()
    {
      let calcular:number = this.item.volumenCalculo -  ((this.item.volumenCalculo * this.porcentaje) / 100)
       this.volumenRecibido = calcular;
       return this.volumenRecibido;
   }

    async guardar()
    {
        try
        {
            this.changeLoading(new Loading(true,"Registrando informacion"));

            let rsp = await ConfirmarTrozas(new ConfirmarTrozasModal(this.item.id,this.usuarioId,this.porcentaje,this.volumenRecibido,this.item.plantaId));

            if(rsp.isSuccess == true)
            {
                this.changeAlerta(new Alerta(rsp.isMessage,true,"primary",1000));
                this.$emit('limpiar');
            }
            else this.changeAlerta(new Alerta(rsp.isMessage,true,"primary",5000));
        }
        catch(error)
        {
            //@ts-ignore
            this.changeAlerta(new Alerta(error,true,"danger",1000));
        }
        finally
        {
            this.changeLoading(new Loading(false,""));
        }
    }

}
